export const translations: any  = {
  "signing": {
    "sms": {
      "FR": "Recevoir un code SMS",
      "NL": "Vraag een code per sms aan",
      "other": "Receive a SMS code"
    },
    "signing-button": {
      "FR": "Signer",
      "NL": "Ondertekenen",
      "other": "Sign"
    },
    "sms-received": {
      "FR": "Vous avez reçu un code SMS",
      "NL": "Je hebt een sms ontvangen",
      "other": "You have received a SMS"
    },
    "document_signed": {
      "FR": "Le document est signé par ",
      "NL": "Het document is ondertekend door ",
      "other": "The document is signed by "
    },
    "document_signed_by": {
      "FR": "Signature électronique réalisée par ",
      "NL": "Elektronische handtekening door ",
      "other": "Electronic signature made by "
    },
    "gdpr_mandatory_sentence": {
      "FR": "Toutes les conditions ci-dessus doivent être acceptées pour pouvoir signer",
      "NL": "Alle bovenstaande voorwaarden moeten worden geaccepteerd om te kunnen tekenen",
      "other": "All the above conditions must be accepted in order to be able to sign"
    },
    "sms_too_many_requested": {
      "FR": "Vous avez essayé trop de fois. Veuillez réessayer dans quelques secondes.",
      "NL": "Je hebt het te vaak geprobeerd. Probeer het over een paar seconden opnieuw.",
      "other": "You have requested too many times, please try again in a few seconds"
    }
  },
  "error": {
    "try-again": {
      "FR": "PLEASE TRY AGAIN LATER",
      "NL": "PLEASE TRY AGAIN LATER",
      "other": "PLEASE TRY AGAIN LATER"
    }
  }
}
