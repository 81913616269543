import {Route, RouterModule, Routes, UrlMatchResult, UrlSegment, UrlSegmentGroup} from "@angular/router";
import {NgModule} from "@angular/core";
import {SigningResolver} from "./signing/signing.resolver";
import {SigningComponent} from "./signing/signing.component";


const routes: Routes = [
  {
    path : "", component: SigningComponent, resolve : { signingData:SigningResolver}},
  { path: 'error', loadChildren: () => import('./error/error.module').then(m => m.ErrorModule) },
  //{ path: "**", redirectTo: "/signme" }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
