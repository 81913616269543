

<div class="container h-100">
  <div class="row h-100 justify-content-center align-items-center">
    <div class="col-10 col-md-8 col-lg-6">
      <form #userForm="ngForm">
        <div id="image">
          <img src="https://www.rbfa.be/assets/img/logo-small.svg">
          <img [src]="imagelink">
        </div>
        <h2>{{signingData.title}}</h2>
        <div class="bg-light border-bottom">
          <div *ngFor="let detail of signingData.sign_details">
            <ng-container *ngIf="detail.content">
              <div *ngIf="isDate(detail) else isNoDate">
                <p>{{detail.label}} <span class="fw-bold">{{detail.content | date}}</span></p>
              </div>
              <ng-template #isNoDate>
                <div>
                  <p>{{detail.label}} <span class="fw-bold">{{detail.content}}</span></p>
                </div>
              </ng-template>
            </ng-container>
          </div>
        </div>
        <div class="mt-5">
          <p>{{signingData.gdprLabel}}</p>
        </div>
        <div>
          <ng-container *ngIf="signingData.gdpr">
            <div *ngFor="let gdpr of signingData.gdpr" class="text-muted small mt-4">
              <input (change)="eventCheck($event, gdpr)" name="gdprBox" type="checkbox"
                     [checked]="gdpr.content === 'true'" [disabled]="isDocumentSigned">{{gdpr.label}}
            </div>
            <div class="fs-6 d-flex align-items-center fst-italic" role="alert">
              <svg width="15" height="15" fill="#ebb434" class="bi bi-exclamation-triangle-fill flex-shrink-0 me-2"
                   viewBox="0 0 16 16" role="img" aria-label="Warning:">
                <path
                  d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
              </svg>
              <div id="gdpr_mandatory_sentence">
                {{"signing.gdpr_mandatory_sentence" | translate: signingData.language}}
              </div>
            </div>
          </ng-container>
        </div>

        <p id="documentIsSigned"
           *ngIf="isDocumentSigned">{{"signing.document_signed" | translate: signingData.language}}{{signingData.signer_name}}</p>
        <div *ngIf="!isDocumentSigned" class="text-center mt-5 mb-5">
          <div>
            <button type="button" [disabled]="isRequestSmsDisabled" (click)="requestSmsCode()"
                    class="btn btn-secondary btn-color w-75">{{"signing.sms" | translate: signingData.language}}
            </button>
            <ng-container *ngIf="hasReceivedSms; else spinner">
              <span *ngIf="isSmsCorrect then success; else error"></span>
              <ng-template #success><p>{{"signing.sms-received" | translate: signingData.language}}</p></ng-template>
              <ng-template #error><p>Error</p></ng-template>
            </ng-container>
            <ng-template #spinner>
              <div class="loader" *ngIf="hasRequestedSms"></div>
            </ng-template>
            <div *ngIf="toManyRequestError">
              <p><span class="fw-bold">{{"signing.sms_too_many_requested" | translate: signingData.language}}</span></p>
            </div>
          </div>
          <div *ngIf="showErrorMessage">
            <p>Error occured</p>
          </div>
          <ng-container *ngIf="isButtonSignClicked && !isCodeSentCorrect">
            <p id="wrongCode">Code is not correct !</p>
          </ng-container>
          <div class="text-center mt-4 mb-5">
            <label for="colFormLabelLg">SMS code</label>
            <input type="text" title="Example: '52145'" minlength="5" maxlength="10" pattern="\d{1,10}"
                   name="userSmsCode"
                   [(ngModel)]="userCodeValue" required id="colFormLabelLg"
                   placeholder="code">
          </div>
          <div>
            <p>{{"signing.document_signed_by" | translate: signingData.language}} {{signingData.signer_name}}</p>
            <button type="button" [disabled]="userForm.invalid || gdprAllChecked" (click)="verifySmsCode()"
                    class="btn btn-primary btn-color w-75">
              {{"signing.signing-button" | translate: signingData.language}}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>


