import {Injectable} from '@angular/core';
import {
  Resolve,
  RouterStateSnapshot,
  ActivatedRouteSnapshot, Router
} from '@angular/router';
import {Observable, EMPTY, catchError} from 'rxjs';
import {SigningService} from "./signing.service";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class SigningResolver implements Resolve<boolean> {

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    let hashKey = encodeURIComponent(route.queryParamMap.get('hash') as string);
    let username = route.queryParamMap.get('username');
    if (hashKey != null && username != null) {
      return this.service.getSigningData(hashKey, username).pipe(
        map(data => {
          if (data) {
            data.hash = hashKey;
            data.username = username;
            return data;
          } else {
              this.router.navigate(["/error", {message: "no data received"}]);
            return EMPTY;
          }
        }), catchError((error) => {
          console.log(error);
          let message;
          switch (error.status) {
            case 400 :
              message = error.error.message;
              break;
            case 500 :
              message = error.error.message;
              break;
            default :
              message = "GENERAL ERROR";
          }
          this.router.navigate(["/error", {message}]);
          return EMPTY;
        })
      );
    }
    this.router.navigate(["/error", {message: "bad input"}]);
    return EMPTY;
  }

  constructor(private service: SigningService, private router: Router) {

  }
}
