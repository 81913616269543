import {Component, getDebugNode, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import { SignDetail, SigningData} from "./user";
import {catchError, map} from "rxjs/operators";
import {SigningService} from "./signing.service";
import {EMPTY, Subscription, throwError} from "rxjs";


@Component({
  selector: 'app-signing',
  templateUrl: './signing.component.html',
  styleUrls: ['./signing.component.scss']
})
export class SigningComponent implements OnInit, OnDestroy {


  optionsChecked: any = [];
  gdprListValue: any = [];
  gdprAllChecked: boolean = true;
  toManyRequestError: boolean = false
  signingData: SigningData;
  isSmsCorrect: boolean;
  isRequestSmsDisabled: boolean = false;
  isDocumentSigned: boolean = false;
  userCodeValue: string;
  private subscription: Subscription ;
  hasReceivedSms: boolean = false;
  hasRequestedSms: boolean = false;
  showErrorMessage: boolean = false;
  isButtonSignClicked: boolean = false;
  isCodeSentCorrect: boolean;
  imagelink: string;

  constructor(private route:ActivatedRoute, private service:SigningService) {
  }

  ngOnInit(): void {
    this.getUserSigningData();
    this.displayLogo(this.signingData.typligue)
    if(this.signingData.gdpr == null){
      this.gdprAllChecked = false;
    }
  }
  displayLogo(channel: string) {
    switch (channel) {
      case 'ACFF':
        this.imagelink = "https://www.acff.be/assets/img/logo.svg";
        break;
      case 'VFV' :
        this.imagelink = "https://www.voetbalvlaanderen.be/assets/img/logo.svg";
        break;
    }
  }


  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getUserSigningData():void{
    this.subscription = this.route.data.pipe(
      map((data) => this.signingData = data['signingData'])
    ).subscribe()
    this.checkDocumentAlreadySigned();
  }

  requestSmsCode(): void {
    this.hasReceivedSms = false;
    this.hasRequestedSms = true;
    this.showErrorMessage = false;
    this.service.requestSmsCode(this.signingData.hash, this.signingData.username).pipe(
      map((data) => {
        this.hasReceivedSms = true;
        this.hasRequestedSms = false;

        if (data['result_code'] != null) {
          if(data['result_code'] == 0){
            this.isSmsCorrect = true;
          }
        } else {
          this.setDelay(data);
        }
      }), catchError(error => {
        this.showErrorMessage = true;
        return EMPTY;
      })
    ).subscribe();
  }

  verifySmsCode() {
    this.service.verifySmsCode(this.signingData.hash, this.signingData.username, this.userCodeValue, this.gdprListValue).pipe(
      map((data) => {
        this.isButtonSignClicked = true;
        if(data['sms_valid']){
          this.isCodeSentCorrect = true;
          this.isDocumentSigned = true;
          this.isButtonSignClicked = false;
        } else {
          this.isCodeSentCorrect = false;
        }
      })
    ).subscribe();
  }


  eventCheck(event: any, value:any) {
    this.gdprAllChecked = true;
    if (event.target.checked) { //Put gdpr value into a list + check if there are all checked to enable sign button
      value.content = true;
      this.gdprListValue= this.gdprListValue.filter((val: any) => val != value);
      this.gdprListValue.push(value);
      this.optionsChecked.push(value);
    }
    else{
      this.optionsChecked= this.optionsChecked.filter((val: any) => val != value);
      value.content = false;
      this.gdprListValue= this.gdprListValue.filter((val: any) => val != value);
      this.gdprListValue.push(value);
    }
    if(this.optionsChecked.length == this.signingData.gdpr.length){
      this.gdprAllChecked = false;
    }
  }

   setDelay(times : number) {
     this.isRequestSmsDisabled = true;
     this.hasReceivedSms = false;
     this.toManyRequestError = true;
      setTimeout(() => {
        this.toManyRequestError = false;
        this.isRequestSmsDisabled = false;
      }, times);
  }

  isDate(detail: SignDetail): boolean {
    return /^(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2})/.test(detail.content)
  }

  private checkDocumentAlreadySigned() {
    if(this.signingData.is_signed){
      this.isDocumentSigned = true;
    }
  }
}
