import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from "@angular/common/http";
import {catchError, EMPTY, map, Observable, of, Subscription} from "rxjs";
import {SmsResponse} from "./user";
import {environment} from "../../environments/environment";
import {ActivatedRoute, Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class SigningService {

  constructor(private http:HttpClient, private router: Router) {}

  getSigningData(hash:string, username:string):Observable<any>{
    let headers = new HttpHeaders({'X-api-key': hash});
    return this.http.post(environment.signingUrl, {hash, username}, {headers}).pipe(
      catchError((error) => {
        this.redirectToErrorPage(error);
        return EMPTY;
      })
    );
    /*this.test = this.http.post(environment.signingUrl, {hash, username});
    console.log(this.test.pipe(
      map((data) => console.log(data))
    ).subscribe());
    return this.test;*/
    
  }

  requestSmsCode(hash: string, username: string):Observable<any>{
    let headers = new HttpHeaders({'X-api-key': hash});
    return this.http.post(environment.signingSMSUrl, {hash, username},{headers}).pipe(
      catchError((error) => {
        if(error.status == 429){
          return of(error.headers.get('x-rate-limit-retry-after-milliseconds'));
        }else {
          this.redirectToErrorPage(error);
        }
      return EMPTY;
    })
    );
    /*console.log("SMS  request send !");
    this.test = this.http.post(environment.signingSMSUrl, {hash, username}).pipe(
      catchError((error) => {
        console.log("ERRORCODE: "+error)
        this.redirectToErrorPage(error);
        return EMPTY;
      })
    );
    return this.test;*/
  }

  verifySmsCode(hash: string, username: string, userCode: string, gdprListValue: []):Observable<any>{
    let headers = new HttpHeaders({'X-api-key': hash});
    return this.http.post(environment.signingSMSVerificationUrl, {hash, username, entered_code : userCode, gdpr : gdprListValue}, {headers}).pipe(
      catchError((error) => {
        this.redirectToErrorPage(error);
        return EMPTY;
      })
    );
    /*console.log("SMS VERIFICATION SENT!");
    this.test = this.http.post(environment.signingSMSVerificationUrl, {hash, username, userCode}).pipe(
      catchError((error) => {
        this.redirectToErrorPage(error);
        return EMPTY;
      })
    );
    return this.test;*/
  }


  redirectToErrorPage(error: any) {
    let message;
    switch (error.status) {
      case 400 :
        message = error.error.message;
        break;
      case 429 :
        message = error.error.message;
        break;
      case 500 :
        message = error.error.message;
        break;
      default :
        message = "GENERAL ERROR";
    }
    this.router.navigate(["/error", {message}]);
  }
}
