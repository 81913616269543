import {Pipe, PipeTransform} from '@angular/core';
import {I18nSelectPipe} from "@angular/common";
import {translations} from "./translations";

@Pipe({
  name: 'translate'
})
export class TranslatePipe extends I18nSelectPipe {

  // @ts-ignore
  override transform(value: string, language: string): string {
    const keys = value.split(".");
    let translation: any = translations;
    for (let i = 0; i < keys.length; i++) {
      translation = translation[keys[i]]
    }
    language = language || 'other';
    return super.transform(language, translation);
  }

}
